<template>
    <div class="card">
        <div class="card-body">
            <div class="form-group col-12 px-0">
                <h5 class="text-uppercase bg-light p-2">
                    Post purchase answers
                </h5>
            </div>
            <div class="row">
                <div
                    v-for="(item, accountIndex) in questionnaire.answers"
                    :key="accountIndex"
                    class="form-group col-12 table-responsive mt-3"
                >
                    <label>{{ getAccountName(item.account) }}'s Answers</label>
                    <table
                        id="order-questionnaires-datatable"
                        class="table table-bordered text-center"
                    >
                        <thead class="thead-light">
                            <tr>
                                <th>Question type</th>
                                <th>Question title</th>
                                <th>Question subtitle</th>
                                <th>Answer</th>
                            </tr>
                        </thead>
                        <tbody>
                            <question-answer
                                v-for="(answer, questionIndex) in getAnswers(
                                    item.answers
                                )"
                                :key="
                                    `questionPostPurchase-${accountIndex}-${questionIndex}`
                                "
                                :question="answer"
                            />
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import QuestionAnswer from '@components/orders/details/QuestionAnswer';

export default {
    components: {
        QuestionAnswer
    },

    props: {
        questionnaire: {
            type: Object,
            required: true
        },
        customer: {
            type: Object,
            required: true
        }
    },

    methods: {
        getAnswers(answers) {
            return answers.filter(answer => !answer.isDefault);
        },

        getAccountName(account) {
            return account.name
                ? account.name
                : `${this.customer.first_name} ${this.customer.last_name}`;
        }
    }
};
</script>
