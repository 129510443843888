<template>
    <div class="card">
        <div class="card-body">
            <h5 class="text-uppercase bg-light p-2">
                Impression Kits
            </h5>
            <div
                v-for="(impressionKit, index) in impressionKits"
                :key="`impression-kit-${index}`"
            >
                <div class="row m-2 info-box" style="flex-direction: column">
                    <span class="font-weight-bold">
                        {{ impressionKit.productColor.product.name }}
                        <span class="badge bg-soft-primary text-primary ml-1">
                            Impression Kit
                        </span>
                    </span>
                    <span>
                        Color:
                        {{ impressionKit.productColor.name }}
                    </span>
                    <span> Size: {{ impressionKit.size }} </span>
                    <span>Quantity: 1</span>
                    <span> Code: {{ impressionKit.code }} </span>
                    <div v-if="impressionKit.exercises_url" class="mt-2">
                        Personal exercises link:
                        <a
                            :href="getExercisesUrl(impressionKit.exercises_url)"
                            target="_blank"
                        >
                            {{ getExercisesUrl(impressionKit.exercises_url) }}
                        </a>
                    </div>
                </div>
                <div class="d-flex flex-wrap">
                    <div class="form-group col-6 mt-2">
                        <label>Link:</label>
                        <input
                            ref="cartLink"
                            :value="getImpressionKitCodeUrl(impressionKit.code)"
                            type="text"
                            class="form-control"
                            readonly="readonly"
                        />
                    </div>
                    <div class="form-group col-xl-2 d-flex align-items-end">
                        <button
                            type="button"
                            class="btn btn-info waves-effect waves-light ml-1"
                            @click="copyToClipboard(impressionKit.code)"
                        >
                            Copy to Clipboard
                        </button>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-12 table-responsive mt-3">
                        <table
                            id="order-questionnaires-datatable"
                            class="table table-bordered text-center"
                        >
                            <thead class="thead-light">
                                <tr>
                                    <th>Question type</th>
                                    <th>Question title</th>
                                    <th>Question subtitle</th>
                                    <th>Answer</th>
                                </tr>
                            </thead>
                            <tbody>
                                <question-answer
                                    v-for="(question, qIndex) in impressionKit.questionnaire"
                                    :key="`question-${index}-${qIndex}`"
                                    :question="question"
                                />
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { frontendUrl } from '@config';
import QuestionAnswer from '@components/orders/details/QuestionAnswer';

export default {
    components: {
        QuestionAnswer
    },

    props: {
        impressionKits: {
            type: Array,
            required: true
        }
    },

    methods: {
        getExercisesUrl(exercisesUrl) {
            return `${frontendUrl}${exercisesUrl}`;
        },

        getImpressionKitCodeUrl(code) {
            return `${frontendUrl}/cart?ikf=${code}`;
        },

        async copyToClipboard(code) {
            const text = this.getImpressionKitCodeUrl(code);

            await navigator.clipboard.writeText(text);

            this.$toaster('Copied to clipboard!');
        }
    }
};
</script>
