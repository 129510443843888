<template>
    <div v-if="showDiscounts" class="card">
        <div class="card-body order-discounts">
            <h5 class="col-12 d-flex text-uppercase bg-light p-2">
                Discounts
            </h5>

            <div
                v-if="order.orderCode.length"
                class="form-group col-md-6"
            >
                <label>Promo Codes</label>
                <div class="table-responsive">
                    <table
                        id="order-codes-datatable"
                        class="table table-bordered table-striped"
                    >
                        <tbody>
                            <tr
                                v-for="orderCode in order.orderCode"
                                :key="orderCode.id"
                                class="text-center"
                            >
                                <td class="w-50">
                                    {{ orderCode.code.name }}
                                </td>
                                <td>
                                    <span
                                        v-if="
                                            orderCode.code.type === 'percentage'
                                        "
                                    >
                                        {{ orderCode.code.discount }}%
                                    </span>
                                    <span
                                        v-else-if="
                                            orderCode.code.type === 'fixed'
                                        "
                                    >
                                        ${{
                                            orderCode.code.discount
                                                | variantFixedPrice
                                        }}
                                    </span>
                                    <span
                                        v-else-if="
                                            orderCode.code.type === 'locked'
                                        "
                                    >
                                        Price:
                                        ${{
                                            orderCode.code.discount
                                                | variantFixedPrice
                                        }}
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div
                v-if="order.orderGiftCard.length"
                class="form-group col-md-9"
            >
                <label>Gift Cards</label>
                <div class="table-responsive">
                    <table
                        id="order-gift-cards-datatable"
                        class="table table-bordered table-striped"
                    >
                        <tbody>
                            <tr
                                v-for="orderGiftCard in order.orderGiftCard"
                                :key="orderGiftCard.id"
                                class="text-center"
                            >
                                <td style="width: 33%">
                                    {{ orderGiftCard.giftCard.code }}
                                </td>
                                <td style="width: 33%">
                                    ${{
                                        orderGiftCard.discount
                                            | variantFixedPrice
                                    }}
                                    {{
                                        orderGiftCard.refunded
                                            ? '(refunded)'
                                            : ''
                                    }}
                                </td>
                                <td>
                                    <router-link
                                        :to="
                                            `/gift-cards/${
                                                orderGiftCard.gift_card_id
                                            }/orders`
                                        "
                                    >
                                        <button
                                            type="button"
                                            class="btn btn-info waves-effect waves-light"
                                        >
                                            Details
                                        </button>
                                    </router-link>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div
                v-if="order.orderImpressionKits.length"
                class="form-group col-md-9"
            >
                <label>Impression Kit</label>
                <div class="table-responsive">
                    <table
                        id="order-codes-datatable"
                        class="table table-bordered table-striped"
                    >
                        <tbody>
                            <tr
                                v-for="orderImpressionKit in order.orderImpressionKits"
                                :key="orderImpressionKit.id"
                                class="text-center"
                            >
                                <td style="width: 33%">
                                    {{ orderImpressionKit.impressionKit.code }}
                                </td>
                                <td style="width: 33%">
                                    ${{
                                        orderImpressionKit.impressionKit.price
                                            | variantFixedPrice
                                    }}
                                </td>
                                <td>
                                    <router-link
                                        :to="
                                            `/orders/${
                                                orderImpressionKit.impressionKit
                                                    .order_id
                                            }`
                                        "
                                    >
                                        <button
                                            type="button"
                                            class="btn btn-info waves-effect waves-light"
                                        >
                                            FeetKit Order
                                        </button>
                                    </router-link>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div v-if="order.oto_discount_accepted" class="form-group col-md-6">
                <label>One Time Offer</label>
                <div class="table-responsive">
                    <div class="table-responsive">
                        <table
                            id="order-codes-datatable"
                            class="table table-bordered table-striped"
                        >
                            <tbody>
                                <tr class="text-center">
                                    <td class="w-50">
                                        Discount
                                    </td>
                                    <td>
                                        ${{
                                            order.discount_oto_discount
                                                | variantFixedPrice
                                        }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        order: {
            type: Object,
            required: true,
            default: () => ({})
        }
    },

    computed: {
        showDiscounts() {
            const {
                orderCode,
                orderGiftCard,
                orderImpressionKits
            } = this.order;

            if (
                (orderCode && orderCode.length) ||
                (orderGiftCard && orderGiftCard.length) ||
                (orderImpressionKits && orderImpressionKits.length) ||
                this.order.oto_discount_accepted
            ) {
                return true;
            }

            return false;
        }
    }
};
</script>

<style lang="scss" scoped>
.order-discounts {
    .table {
        margin-bottom: 0;

        tr {
            td:nth-of-type(3) {
                background-color: white;
                border: none;
                text-align: left;
                padding: 5px 20px;
            }
        }
    }
}
</style>
