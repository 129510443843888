<template>
    <div class="card-box">
        <h5 class="p-2 m-0 mb-2 text-uppercase">
            Status
        </h5>
        <div class="d-flex flex-wrap">
            <div class="form-group col-xl-6">
                <b-form-select v-model="status" :options="statuses" />
            </div>
            <div v-show="status === 'FULFILLED'" class="form-group col-xl-4">
                <label for="trackingCode">Tracking Code</label>
                <input
                    id="trackingCode"
                    v-model="tracking"
                    type="text"
                    class="form-control"
                    :class="tracking === '' ? 'is-invalid' : ''"
                />
            </div>
            <div class="form-group col-xl-2 d-flex align-items-end">
                <button
                    type="button"
                    class="btn btn-danger waves-effect waves-light ml-1"
                    @click="changeStatus"
                >
                    Change
                </button>
            </div>
        </div>

        <order-history-table :histories="history" />
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import OrderHistoryTable from '@components/orders/OrderHistoryTable';

export default {
    components: {
        OrderHistoryTable
    },

    props: {
        order: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            status: null,
            tracking: null,
            history: [],
            statuses: [
                { text: 'PAID', value: 'PAID', id: 0 },
                { text: 'PAID-APP', value: 'PAID-APP', id: 1 },
                { text: 'PAID-WAITING', value: 'PAID-WAITING', id: 2 },
                { text: 'APP-PAID', value: 'APP-PAID', id: 3 },
                { text: 'FULFILLED', value: 'FULFILLED', id: 4 },
                { text: 'PENDING', value: 'PENDING', id: 5 },
                { text: 'UPGRADING', value: 'UPGRADING', id: 6 },
                { text: 'CANCELLED', value: 'CANCELLED', id: 7 },
                { text: 'IKF-PAID', value: 'IKF-PAID', id: 8 },
                { text: 'IKF-PENDING', value: 'IKF-PENDING', id: 9 },
                { text: 'IKF-UPGRADING', value: 'IKF-UPGRADING', id: 10 },
                { text: 'IKF-COMPLETE', value: 'IKF-COMPLETE', id: 11 }
            ]
        };
    },

    mounted() {
        this.status = this.order.status;
        this.tracking = this.order.tracking;
        this.history = [...this.order.history];
    },

    methods: {
        ...mapActions({
            changeOrderStatus: 'orders/changeStatus'
        }),

        async changeStatus() {
            if (this.status === 'FULFILLED' && this.tracking === '') {
                return;
            }

            if (this.order.orderGiftCard.some(giftCard => giftCard.refunded)) {
                this.$toasterError(
                    'Status cannot be changed, because gift card was refunded.'
                );

                return;
            }

            if (
                this.status === 'CANCELLED' &&
                this.order.orderGiftCard.length
            ) {
                const confirmed = await this.$confirmationModal(
                    'Funds will be returned to the gift card. This action cannot be undone.'
                );

                if (!confirmed) {
                    return;
                }
            }

            try {
                const newStatus = await this.changeOrderStatus({
                    id: this.order.id,
                    status: this.status,
                    tracking: this.tracking
                });

                this.$toaster('Status has been updated!');

                this.history = [newStatus, ...this.history];
            } catch (err) {
                console.error(err);

                this.$toasterError();
            }
        }
    }
};
</script>
