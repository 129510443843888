<template>
    <div class="card-box">
        <h5 class="text-uppercase bg-light p-2 mb-3">
            Gift Cards
        </h5>
        <div class="row">
            <div
                v-for="(giftCard, index) in giftCards"
                :key="`giftCard-${index}`"
                class="col-xl-4 col-lg-6 col-12"
            >
                <div class="card border-blue border mb-3 ribbon-box">
                    <div class="card-body font-weight-bold">
                        <div class="ribbon-two ribbon-two-blue">
                            <span>&nbsp;</span>
                        </div>
                        <h4 class="card-title text-blue ml-3">
                            Gift Card
                        </h4>
                        <div class="row">
                            <div class="col-4">
                                Code:
                            </div>
                            <div class="col-8">
                                {{ giftCard.code }}
                            </div>
                            <div class="col-4">
                                Card Value:
                            </div>
                            <div
                                v-if="giftCard.type === 'quantity'"
                                class="col-8"
                            >
                                {{ formatCardValue(giftCard) }}
                            </div>
                            <div v-else class="col-8">
                                ${{
                                    giftCard.initial_value | variantFixedPrice
                                }}
                            </div>
                            <div class="col-4">
                                Sender:
                            </div>
                            <div class="col-8">
                                {{ giftCard.sender_name }},
                                {{ giftCard.sender_email }}
                            </div>
                            <div class="col-4">
                                Recipient:
                            </div>
                            <div class="col-8">
                                {{ giftCard.receiver_name }},
                                {{ giftCard.receiver_email }}
                            </div>
                            <div class="col-4">
                                Delivery Date:
                            </div>
                            <div class="col-8">
                                {{ giftCard.delivery_date | dateOnly }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        giftCards: {
            type: Array,
            required: true
        }
    },

    methods: {
        formatCardValue(giftCard) {
            return `${giftCard.initial_value} pair${
                giftCard.initial_value > 1 ? 's' : ''
            }`;
        }
    }
};
</script>
