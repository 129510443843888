<template>
    <div v-if="products.length" class="card-box">
        <div v-for="(account, index) in accounts" :key="`account-${index}`">
            <h5 class="text-uppercase bg-light p-2 mb-3">
                {{ getCustomerName(account) }}'s products
            </h5>
            <div
                v-for="(product, pIndex) in account.products"
                :key="`product-${index}-${pIndex}`"
            >
                <div class="m-2 info-box">
                    <h5 class="mb-1">
                        {{ product.product && product.product.name }}
                        <span
                            v-if="product.from_one_time_offer"
                            class="badge bg-soft-success text-success ml-1"
                        >
                            One Time Offer
                            {{ product.quantity > product.one_time_offer_quantity ? 'Included' : '' }}
                        </span>
                        <span
                            v-if="product.from_reorder"
                            class="badge bg-soft-blue text-blue ml-1"
                        >
                            {{
                                product.magento_order_number
                                    ? 'Magento'
                                    : ''
                            }}
                            Reorder
                        </span>
                        <span
                            v-if="product.from_reorder && (product.reorder_number || product.magento_order_number)"
                            class="badge bg-soft-blue text-blue ml-1"
                        >
                            {{ product.reorder_number || product.magento_order_number }}
                        </span>
                        <span
                            v-if="product.from_bundle"
                            class="badge bg-soft-info text-info ml-1"
                        >
                            Bundle
                        </span>
                    </h5>

                    <div class="mb-1">
                        <span class="font-weight-bold mr-1">
                            SKU:
                        </span>
                        {{ product.product.sku || product.assigned_sku }}
                        <span
                            v-if="product.assigned_sku"
                            class="badge bg-soft-teal text-teal ml-1"
                        >
                            Assigned
                        </span>
                    </div>
                    <div class="mb-1">
                        <span class="font-weight-bold mr-1">
                            Color:
                        </span>
                        <div
                            :style="{
                                'background-color': product.productColor.hex
                            }"
                            class="colour-block mr-1"
                            :class="{ 'is-white': product.productColor.hex === '#FFFFFF' }"
                        />
                        {{ product.productColor.name }}
                    </div>
                    <p class="mb-1">
                        <span class="font-weight-bold mr-1">
                            Size:
                        </span>
                        {{ product.size }}
                    </p>
                    <p class="mb-1">
                        <span class="font-weight-bold mr-1">
                            Quantity:
                        </span>
                        {{ product.quantity }}
                    </p>
                    <p class="mb-1">
                        <span class="font-weight-bold mr-1">
                            Product Price:
                        </span>
                        ${{ product.price | variantFixedPrice }}
                    </p>
                    <p v-if="product.final_prices" class="mb-1">
                        <span class="font-weight-bold mr-1">
                            Final Prices:
                        </span>
                        <span v-for="(price, fIndex) in product.final_prices" :key="fIndex">
                            <b class="bg-light mr-1">
                                ${{ price | variantFixedPrice }}
                            </b>
                        </span>
                    </p>
                    <p v-if="product.assign_product_removable_inserts" class="mb-1">
                        <span class="font-weight-bold mr-1">
                            Inserts Length:
                        </span>
                        {{ product.product_removable_inserts ? 'Full' : '3/4' }}
                    </p>
                    <div v-if="product.exercises_url" class="mb-1">
                        <span class="font-weight-bold mr-1">
                            Exercises Link:
                        </span>
                        <a
                            :href="getExercisesUrl(product.exercises_url)"
                            target="_blank"
                        >
                            {{ getExercisesUrl(product.exercises_url) }}
                        </a>
                    </div>
                </div>

                <div v-if="product.extensions.length" class="p-2 info-box bg-light">
                    <h5 class="mb-2">
                        <i class="mdi mdi-shield-check me-1"></i>
                        Extensions
                    </h5>

                    <p
                        v-for="(extension, eIndex) in product.extensions"
                        :key="eIndex"
                        class="mb-1"
                    >
                        <span class="font-weight-bold mr-1">
                            {{ extension.productExtension.name }}:
                        </span>
                        ${{ extension.price | variantFixedPrice }}
                    </p>
                </div>

                <div class="row">
                    <div class="form-group col-12 table-responsive mt-3">
                        <table
                            v-if="product.questionnaire.length"
                            id="order-questionnaires-datatable"
                            class="table table-bordered text-center"
                        >
                            <thead class="thead-light">
                                <tr>
                                    <th>Question type</th>
                                    <th>Question title</th>
                                    <th>Question subtitle</th>
                                    <th>Answer</th>
                                </tr>
                            </thead>
                            <tbody>
                                <question-answer
                                    v-for="(question, qIndex) in getQuestions(
                                        product.questionnaire,
                                        account,
                                        product
                                    )"
                                    :key="
                                        `question-${index}-${pIndex}-${qIndex}`
                                    "
                                    :question="question"
                                />
                            </tbody>
                        </table>

                        <table
                            v-else
                            class="table table-bordered text-center table-striped"
                        >
                            <tr>
                                <th>
                                    Your questionnaire information is stored in
                                    secure place! Order number:
                                    {{ product.magento_order_number }}
                                </th>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import QuestionAnswer from '@components/orders/details/QuestionAnswer';
import { frontendUrl } from '@config';

export default {
    components: {
        QuestionAnswer
    },

    props: {
        products: {
            type: Array,
            required: true
        },
        customer: {
            type: Object,
            required: false,
            default: () => ({})
        },
        postPurchaseQuestionnaire: {
            type: Object,
            required: false,
            default: () => ({})
        }
    },

    data: () => ({
        accounts: []
    }),

    watch: {
        products: {
            async handler() {
                this.products.forEach(product => {
                    if (!product.account) {
                        product.account = {
                            index: 0
                        };
                    }

                    const accountIndex = this.accounts.findIndex(
                        account => account.info.index === product.account.index
                    );

                    if (accountIndex === -1) {
                        this.accounts.push({
                            info: product.account,
                            products: [product]
                        });

                        return;
                    }

                    this.accounts[accountIndex].products.push(product);
                });
            }
        }
    },

    methods: {
        getCustomerName(account) {
            return account.info.name
                ? account.info.name
                : `${this.customer.first_name} ${this.customer.last_name}`;
        },

        getQuestions(productQuestionnaire, account, product) {
            if (!this.postPurchaseQuestionnaire) {
                return productQuestionnaire;
            }

            const currentAccountQuestionnaire = this.postPurchaseQuestionnaire.answers.find(
                item => item.account.index === account.info.index
            );
            const defaultPostPurchaseQuestions = currentAccountQuestionnaire.answers.filter(
                answer => answer.isDefault && answer.productId === product.id
            );

            return [...productQuestionnaire, ...defaultPostPurchaseQuestions];
        },

        getExercisesUrl(exercisesUrl) {
            return `${frontendUrl}${exercisesUrl}`;
        }
    }
};
</script>

<style lang="scss" scoped>
.colour-block {
    display: inline-block;
    vertical-align: text-top;
    height: 17px;
    width: 17px;
    flex-shrink: 0;
    border-radius: 50%;
    background-color: #f1f5f7;

    &.is-white {
        border: 1px solid #ced4da;
    }
}
</style>
