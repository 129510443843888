<template>
    <div class="d-flex flex-wrap">
        <h5
            class="col-12 text-uppercase bg-light p-2"
        >
            General
        </h5>
        <div class="form-group col-md-6">
            <label>Order Number</label>
            <input
                :value="order.order_number || order.magento_order_number"
                type="text"
                class="form-control"
                disabled
            />
        </div>
        <div class="form-group col-md-6">
            <label>Total</label>
            <input :value="total" type="text" class="form-control" disabled />
        </div>
        <div class="form-group col-md-6">
            <label>Customer Full Name</label>
            <input
                :value="order.full_name"
                type="text"
                class="form-control"
                disabled
            />
        </div>
        <div class="form-group col-md-6">
            <label>Customer Email</label>
            <input
                :value="order.email"
                type="text"
                class="form-control"
                disabled
            />
        </div>
        <div class="form-group col-md-6">
            <label>Phone Number</label>
            <input
                :value="order.phone_number"
                type="text"
                class="form-control"
                disabled
            />
        </div>
        <div class="form-group col-md-6">
            <label>Payment Method</label>
            <input
                :value="
                    order.payment_method && order.payment_method.toUpperCase()
                "
                type="text"
                class="form-control"
                disabled
            />
        </div>
        <div class="form-group col-md-6">
            <label>Company Name</label>
            <input
                :value="order.company"
                type="text"
                class="form-control"
                disabled
            />
        </div>
        <div class="form-group col-md-6">
            <label>Last Update</label>
            <input
                :value="order.updated_at | formatDate"
                type="text"
                class="form-control"
                disabled
            />
        </div>
        <div class="form-group col-md-6">
            <label>One Time Offer</label>
            <input
                :value="otoValue"
                type="text"
                class="form-control"
                disabled
            />
        </div>
        <div class="form-group col-md-6">
            <label>Initial Url</label>
            <input
                :value="initialUrl"
                type="text"
                class="form-control"
                disabled
            />
        </div>
        <div class="form-group col-md-6">
            <label>Purchase Session Url</label>
            <input
                :value="sessionInitialUrl"
                type="text"
                class="form-control"
                disabled
            />
        </div>
        <div class="form-group col-md-6">
            <label>Post Purchase Url</label>
            <input
                :value="postPurchaseUrl"
                type="text"
                class="form-control"
                disabled
            />
        </div>
        <div class="form-group col-md-6">
            <label>Post Purchase Completed At</label>
            <input
                :value="order.post_purchase_completed_at | formatDate"
                type="text"
                class="form-control"
                disabled
            />
        </div>
        <div class="form-froup col-md-12 d-flex align-items-end p-1 mb-2">
            <a
                v-if="order.invoice"
                class="btn btn-info waves-effect waves-light ml-1"
                :href="invoiceUrl"
                download
                target="_blank"
            >
                <span class="btn-label">
                    <i class="fe-file-text" />
                </span>
                Download Old Invoice
            </a>
            <button
                v-if="!order.magento_order_number"
                class="btn btn-info waves-effect waves-light ml-1"
                :disabled="isGenerating"
                @click="getDetailedInvoice(false)"
            >
                <span class="btn-label">
                    <i class="fe-file-text" />
                </span>
                {{ isGenerating ? 'Working...' : 'Download Invoice' }}
            </button>
            <button
                type="button"
                :disabled="!refreshEnabled || isGenerating"
                class="btn ml-1 btn-info"
                :class="{ disabled: isGenerating }"
                @click="getDetailedInvoice(true)"
            >
                <i class="fe-refresh-cw mr-1" />
                Refresh
            </button>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import startCase from 'lodash/startCase';
import config from '@config';
import { mapActions } from 'vuex';

export default {
    props: {
        order: {
            type: Object,
            required: true
        }
    },

    data: () => ({
        total: '$0',
        isGenerating: false
    }),

    computed: {
        invoiceUrl() {
            return `${config.apiUrl}/invoices/${this.order.invoice}`;
        },

        initialUrl() {
            if (!this.order.initial_url) {
                return '';
            }

            return `${config.frontendUrl}${this.order.initial_url}`;
        },

        sessionInitialUrl() {
            if (!this.order.session_initial_url) {
                return '';
            }

            return `${config.frontendUrl}${this.order.session_initial_url}`;
        },

        postPurchaseUrl() {
            return `${config.frontendUrl}/post-purchase/${this.order.id}`;
        },

        otoValue() {
            const otoTypes = [];

            const regularOto =
                this.order.orderProductColor &&
                this.order.orderProductColor.some(
                    product => product.from_one_time_offer
                );

            if (regularOto) {
                otoTypes.push('Orthotics');
            }

            const secondaryOto =
                this.order.orderSecondaryProduct &&
                this.order.orderSecondaryProduct.find(
                    product => product.from_one_time_offer
                );

            if (secondaryOto) {
                otoTypes.push(startCase(secondaryOto.secondaryProduct.type));
            }

            if (this.order.oto_discount_accepted) {
                otoTypes.push('Discount');
            }

            if (!otoTypes.length) {
                return 'No';
            }

            return otoTypes.join(', ');
        },

        refreshEnabled() {
            if (!this.order.created_at) {
                return false;
            }

            return moment(this.order.created_at).isAfter('10-11-2023');
        }
    },

    watch: {
        'order.total': function() {
            if (this.order.total) {
                this.total = '$' + (this.order.total / 100).toFixed(2);
            }
        }
    },

    methods: {
        ...mapActions({
            generateDetailedInvoice: 'orders/generateDetailedInvoice'
        }),

        async getDetailedInvoice(refresh) {
            this.isGenerating = true;
            let url = this.order.detailed_invoice;

            try {
                if (!url || refresh) {
                    url = await this.generateDetailedInvoice({
                        id: this.order.id,
                        refresh
                    });
                }

                url = `${config.apiUrl}/invoices/${url}`;

                const fileLink = document.createElement('a');
                fileLink.href = url;
                fileLink.setAttribute(
                    'download',
                    `invoice_${this.order.id}.pdf`
                );
                fileLink.setAttribute('target', `_blank`);
                document.body.appendChild(fileLink);
                fileLink.click();
            } catch (error) {
                console.error(error);

                let message = 'Something went wrong!';

                if (
                    error.response &&
                    error.response.status === 422 &&
                    error.response.data.error
                ) {
                    message = error.response.data.error;
                }

                this.$toasterError(message);
            }

            this.isGenerating = false;
        }
    }
};
</script>
