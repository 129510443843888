<template>
    <div class="card-box">
        <h5 class="p-2 m-0 mb-2 text-uppercase">
            <i class="fe-package me-1" />
            Other products
        </h5>

        <div class="table-responsive">
            <table class="table table-bordered mb-0">
                <thead class="thead-light">
                    <tr>
                        <th>#</th>
                        <th>Product</th>
                        <th>SKU</th>
                        <th>Quantity</th>
                        <th>Product Price</th>
                        <th>Final Price</th>
                        <th>Tax</th>
                        <th>Taxed Price</th>
                        <th style="width: 20%;">
                            Additional Info
                        </th>
                        <th style="width: 8%;">
                            Actions
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        v-for="(item, index) in orderSecondaryProducts"
                        :key="`secondaryProduct-${index}`"
                    >
                        <td>{{ index + 1 }}</td>
                        <td>
                            <b>{{ item.secondaryProduct && item.secondaryProduct.name }}</b>
                        </td>
                        <td>{{ item.secondaryProduct.sku }}</td>
                        <td>{{ item.quantity }}</td>
                        <td>${{ item.price | variantFixedPrice }}</td>
                        <td>
                            <b v-if="item.final_prices !== null">
                                ${{ item.final_prices[0] | variantFixedPrice }}
                            </b>
                            <b v-else>
                                -
                            </b>
                        </td>
                        <td>
                            <span v-if="item.taxes !== null" class="bg-light">
                                ${{ item.taxes[0] | variantFixedPrice }}
                            </span>
                            <span v-else>
                                -
                            </span>
                        </td>
                        <td>
                            <b v-if="item.final_prices !== null && item.taxes !== null">
                                ${{ item.final_prices[0] + item.taxes[0] | variantFixedPrice }}
                            </b>
                            <b v-else>
                                -
                            </b>
                        </td>
                        <td>
                            <span
                                v-if="item.is_cancelled"
                                class="badge bg-soft-danger text-danger mr-1"
                            >
                                Cancelled
                            </span>
                            <span
                                v-if="item.from_reorder"
                                class="badge bg-soft-blue text-blue mr-1"
                            >
                                Reorder
                            </span>
                            <span
                                v-if="item.from_one_time_offer"
                                class="badge bg-soft-success text-success mr-1"
                            >
                                One Time Offer
                            </span>
                            <span
                                v-if="item.from_bundle"
                                class="badge bg-soft-info text-info"
                            >
                                Bundle
                            </span>
                            <span
                                v-if="item.from_question"
                                class="badge bg-soft-primary text-primary"
                            >
                                Quantity Question
                            </span>
                        </td>
                        <td>
                            <a
                                v-if="item.is_cancelled"
                                v-b-tooltip.hover.left
                                title="Restore"
                                href="#"
                                class="action-icon"
                                @click.prevent="onRestore(item, index)"
                            >
                                <i class="fas fa-undo" />
                            </a>
                            <a
                                v-else
                                v-b-tooltip.hover.left
                                title="Cancel"
                                href="#"
                                class="action-icon"
                                @click.prevent="onCancel(item, index)"
                            >
                                <i class="fas fa-ban" />
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <th scope="row" colspan="5" class="text-right">
                            Total :
                        </th>
                        <td>
                            <b v-if="totalFinalPrice">
                                ${{ totalFinalPrice | variantFixedPrice }}
                            </b>
                            <b v-else>
                                -
                            </b>
                        </td>
                        <td>
                            <span v-if="totalTax !== null" class="bg-light">
                                ${{ totalTax | variantFixedPrice }}
                            </span>
                            <span v-else>
                                -
                            </span>
                        </td>
                        <td>
                            <b v-if="totalTaxedPrice">
                                ${{ totalTaxedPrice | variantFixedPrice }}
                            </b>
                            <b v-else>
                                -
                            </b>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    props: {
        secondaryProducts: {
            type: Array,
            required: true
        }
    },

    data() {
        return {
            orderSecondaryProducts: []
        };
    },

    computed: {
        productsList() {
            const list = [];

            this.secondaryProducts.forEach(product => {
                for (let i = 0; i < product.quantity; i++) {
                    list.push({
                        ...product,
                        quantity: 1,
                        final_price:
                            product.final_prices && product.final_prices[i],
                        tax: product.taxes && product.taxes[i]
                    });
                }
            });

            return list;
        },

        totalFinalPrice() {
            return this.productsList.reduce(
                (counter, currentItem) => counter + currentItem.final_price,
                0
            );
        },

        totalTax() {
            return this.productsList.reduce(
                (counter, currentItem) => counter + currentItem.tax,
                0
            );
        },

        totalTaxedPrice() {
            return this.productsList.reduce(
                (counter, currentItem) => counter + currentItem.final_price + currentItem.tax,
                0
            );
        }
    },

    mounted() {
        this.orderSecondaryProducts = [...this.secondaryProducts].sort((a, b) =>
            a.secondaryProduct.sku.localeCompare(b.secondaryProduct.sku)
        );
    },

    methods: {
        ...mapActions({
            cancelOrderSecondaryProduct: 'orderSecondaryProducts/cancel',
            restoreOrderSecondaryProduct: 'orderSecondaryProducts/restore'
        }),

        async onCancel(item, index) {
            const confirmed = await this.$confirmationModal(
                `Are you sure you want to cancel this product?`
            );

            if (!confirmed) {
                return;
            }

            try {
                const orderSecondaryProduct = await this.cancelOrderSecondaryProduct(
                    item.id
                );

                this.$set(this.orderSecondaryProducts, index, {
                    ...item,
                    ...orderSecondaryProduct
                });

                this.$toaster('Product has been canceled!');
            } catch (err) {
                console.error(err);

                this.$toasterError();
            }
        },

        async onRestore(item, index) {
            const confirmed = await this.$confirmationModal(
                `Are you sure you want to restore this product?`
            );

            if (!confirmed) {
                return;
            }

            try {
                const orderSecondaryProduct = await this.restoreOrderSecondaryProduct(
                    item.id
                );

                this.$set(this.orderSecondaryProducts, index, {
                    ...item,
                    ...orderSecondaryProduct
                });

                this.$toaster('Product has been restored!');
            } catch (err) {
                console.error(err);

                this.$toasterError();
            }
        }
    }
};
</script>
