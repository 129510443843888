<template>
    <div class="card-box">
        <h5 class="p-2 m-0 mb-2 text-uppercase">
            Products
        </h5>

        <div class="table-responsive">
            <table class="table table-bordered mb-0">
                <thead class="thead-light">
                    <tr>
                        <th>#</th>
                        <th>Product</th>
                        <th>SKU</th>
                        <th>Quantity</th>
                        <th>Color</th>
                        <th>Size</th>
                        <th>Product Price</th>
                        <th>Final Price</th>
                        <th>Tax</th>
                        <th>Taxed Price</th>
                        <th style="width: 20%;">
                            Additional Info
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        v-for="(item, index) in productsList"
                        :key="`secondaryProduct-${index}`"
                    >
                        <td>{{ index + 1 }}</td>
                        <td>
                            <b>{{ item.product.name }}</b>
                        </td>
                        <td>
                            {{ item.product.sku || item.assigned_sku }}
                        </td>
                        <td>{{ item.quantity }}</td>
                        <td>
                            <div
                                v-if="!item.isExtension"
                                :style="{
                                    'background-color': item.productColor.hex
                                }"
                                class="colour-block mr-1"
                                :class="{ 'is-white': item.productColor.hex === '#FFFFFF' }"
                            />
                            {{ item.productColor.name }}
                        </td>
                        <td>{{ item.size }}</td>
                        <td>${{ item.price | variantFixedPrice }}</td>
                        <td>
                            <b v-if="item.final_price !== null">
                                ${{ item.final_price | variantFixedPrice }}
                            </b>
                            <b v-else>
                                -
                            </b>
                        </td>
                        <td>
                            <span v-if="item.tax !== null" class="bg-light">
                                ${{ item.tax | variantFixedPrice }}
                            </span>
                            <span v-else>
                                -
                            </span>
                        </td>
                        <td>
                            <b v-if="item.final_price !== null && item.tax !== null">
                                ${{ item.final_price + item.tax | variantFixedPrice }}
                            </b>
                            <b v-else>
                                -
                            </b>
                        </td>
                        <td>
                            <span
                                v-if="item.from_one_time_offer"
                                class="badge bg-soft-success text-success mr-1"
                            >
                                One Time Offer
                            </span>
                            <span
                                v-else-if="item.from_reorder"
                                class="badge bg-soft-blue text-blue mr-1"
                            >
                                {{ item.magento_order_number ? 'Magento' : '' }}
                                Reorder
                            </span>
                            <span
                                v-if="item.from_bundle"
                                class="badge bg-soft-info text-info"
                            >
                                Bundle
                            </span>
                            <span
                                v-if="item.isExtension"
                                class="badge bg-soft-orange text-orange"
                            >
                                Extension
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <th scope="row" colspan="7" class="text-right">
                            Total :
                        </th>
                        <td>
                            <b v-if="totalFinalPrice">
                                ${{ totalFinalPrice | variantFixedPrice }}
                            </b>
                            <b v-else>
                                -
                            </b>
                        </td>
                        <td>
                            <span v-if="totalTax !== null" class="bg-light">
                                ${{ totalTax | variantFixedPrice }}
                            </span>
                            <span v-else>
                                -
                            </span>
                        </td>
                        <td>
                            <b v-if="totalTaxedPrice">
                                ${{ totalTaxedPrice | variantFixedPrice }}
                            </b>
                            <b v-else>
                                -
                            </b>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        products: {
            type: Array,
            required: true
        }
    },

    computed: {
        productsList() {
            const sortedProducts = [...this.products].sort(
                (a, b) => a.cart_order - b.cart_order
            );

            const list = [];

            sortedProducts.forEach(product => {
                for (let i = 0; i < product.quantity; i++) {
                    list.push({
                        ...product,
                        quantity: 1,
                        final_price:
                            product.final_prices && product.final_prices[i],
                        tax: product.taxes && product.taxes[i],
                        from_one_time_offer:
                            product.one_time_offer_quantity + i >=
                            product.quantity
                    });
                }

                for (const extension of product.extensions) {
                    list.push({
                        product: {
                            name: extension.productExtension.name
                        },
                        productColor: {},
                        price: extension.price,
                        final_price: extension.price,
                        tax: 0,
                        isExtension: true
                    })
                }
            });

            return list;
        },

        totalFinalPrice() {
            return this.productsList.reduce(
                (counter, currentItem) => counter + currentItem.final_price,
                0
            );
        },

        totalTax() {
            return this.productsList.reduce(
                (counter, currentItem) => counter + currentItem.tax,
                0
            );
        },

        totalTaxedPrice() {
            return this.productsList.reduce(
                (counter, currentItem) =>
                    counter + currentItem.final_price + currentItem.tax,
                0
            );
        }
    }
};
</script>

<style lang="scss" scoped>
.colour-block {
    display: inline-block;
    vertical-align: text-top;
    height: 19px;
    width: 19px;
    flex-shrink: 0;
    border-radius: 50%;
    background-color: #f1f5f7;

    &.is-white {
        border: 1px solid #ced4da;
    }
}
</style>
