<template>
    <div class="d-flex flex-wrap">
        <h5
            class="col-12 d-flex justify-content-between text-uppercase bg-light p-2"
        >
            Shipping address
        </h5>
        <div class="form-group col-md-6">
            <label>Country</label>
            <b-form-select
                v-if="editMode"
                v-model="data.shipping_country"
                :options="countryOptions"
                @change="onCountryChange"
            />
            <input
                v-else
                :value="data.shipping_country"
                type="text"
                class="form-control"
                :disabled="!editMode"
            />
        </div>
        <div class="form-group col-md-6">
            <label>State</label>
            <b-form-select
                v-if="editMode"
                v-model="data.shipping_state"
                :options="stateOptions"
            />
            <input
                v-else
                :value="data.shipping_state"
                type="text"
                class="form-control"
                :disabled="!editMode"
            />
        </div>
        <div class="form-group col-md-6">
            <label>City</label>
            <input
                v-model="data.shipping_city"
                type="text"
                class="form-control"
                :disabled="!editMode"
            />
        </div>
        <div class="form-group col-md-6">
            <label>Zip code</label>
            <input
                v-model="data.shipping_zip"
                type="text"
                class="form-control"
                :disabled="!editMode"
            />
        </div>
        <div class="form-group col-md-6">
            <label>Address</label>
            <input
                v-model="data.shipping_address"
                type="text"
                class="form-control"
                :disabled="!editMode"
            />
        </div>
        <div class="form-group col-md-6">
            <label>Apartment suite</label>
            <input
                v-model="data.shipping_apartment_suite"
                type="text"
                class="form-control"
                :disabled="!editMode"
            />
        </div>

        <div class="col-12">
            <div class="text-right">
                <button
                    v-if="!editMode"
                    class="btn btn-success"
                    @click="editMode = true"
                >
                    <i class="fe-edit mr-1" />
                    Edit
                </button>
                <button
                    v-if="editMode"
                    class="btn btn-success ml-1"
                    @click="onSave"
                >
                    <i class="fas fa-save mr-1" />
                    Save
                </button>
                <button
                    v-if="editMode"
                    class="btn btn-light ml-1"
                    @click="onCancel"
                >
                    Cancel
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    props: {
        order: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            editMode: false,
            data: {},
            countryOptions: ['United States', 'Canada', 'Israel']
        };
    },

    computed: {
        stateOptions() {
            if (this.data.shipping_country === 'United States') {
                return [
                    { value: null, text: '' },
                    'Alabama',
                    'Alaska',
                    'Arizona',
                    'Arkansas',
                    'California',
                    'Colorado',
                    'Connecticut',
                    'Delaware',
                    'District Of Columbia',
                    'Federated States Of Micronesia',
                    'Florida',
                    'Georgia',
                    'Hawaii',
                    'Idaho',
                    'Illinois',
                    'Indiana',
                    'Iowa',
                    'Kansas',
                    'Kentucky',
                    'Louisiana',
                    'Maine',
                    'Marshall Islands',
                    'Maryland',
                    'Massachusetts',
                    'Michigan',
                    'Minnesota',
                    'Mississippi',
                    'Missouri',
                    'Montana',
                    'Nebraska',
                    'Nevada',
                    'New Hampshire',
                    'New Jersey',
                    'New Mexico',
                    'New York',
                    'North Carolina',
                    'North Dakota',
                    'Ohio',
                    'Oklahoma',
                    'Oregon',
                    'Palau',
                    'Pennsylvania',
                    'Rhode Island',
                    'South Carolina',
                    'South Dakota',
                    'Tennessee',
                    'Texas',
                    'Utah',
                    'Vermont',
                    'Virginia',
                    'Washington',
                    'West Virginia',
                    'Wisconsin',
                    'Wyoming'
                ]
            }

            if (this.data.shipping_country === 'Canada') {
                return [
                    { value: null, text: '' },
                    'Alberta',
                    'British Columbia',
                    'Manitoba',
                    'New Brunswick',
                    'Newfoundland and Labrador',
                    'Northwest Territories',
                    'Nova Scotia',
                    'Nunavut',
                    'Ontario',
                    'Prince Edward Island',
                    'Quebec',
                    'Saskatchewan',
                    'Yukon Territory'
                ]
            }

            return [{ value: null, text: '' }];
        }
    },

    mounted() {
        this.setInitialValue();
    },

    methods: {
        ...mapActions({
            updateOrder: 'orders/update'
        }),

        setInitialValue() {
            const {
                shipping_country,
                shipping_state,
                shipping_city,
                shipping_zip,
                shipping_address,
                shipping_apartment_suite
            } = this.order;

            this.data = {
                shipping_country,
                shipping_state,
                shipping_city,
                shipping_zip,
                shipping_address,
                shipping_apartment_suite
            };
        },

        onCountryChange() {
            this.data.shipping_state = null;
        },

        onCancel() {
            this.editMode = false;

            this.setInitialValue();
        },

        async onSave() {
            try {
                await this.updateOrder({
                    id: this.order.id,
                    ...this.data
                });

                this.editMode = false;

                this.$emit('update', this.data);

                this.$toaster('Shipping address has been updated!');
            } catch (err) {
                console.error(err);

                this.$toasterError();
            }
        }
    }
};
</script>
