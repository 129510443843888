<template>
    <layout>
        <page-header :title="title" :items="items" />
        <div class="row">
            <div class="col-12">
                <div class="card-title">
                    {{ 'ID: ' + order.id + ' [' + order.email + ']' }}
                </div>

                <div class="card-box">
                    <order-details-info-table :order="order" />

                    <order-shipping-address
                        v-if="order.shipping_country"
                        :order="order"
                        @update="onUpdate"
                    />
                </div>

                <order-discounts
                    :order="order"
                />

                <order-status
                    v-if="order.id"
                    :order="order"
                />

                <order-logistics-status
                    v-if="order.id"
                    :order="order"
                />

                <order-products
                    v-if="order.orderProductColor && order.orderProductColor.length"
                    :products="products"
                />

                <order-products-details
                    :products="products"
                    :customer="order.customer"
                    :post-purchase-questionnaire="
                        order.postPurchaseQuestionnaire
                    "
                />

                <order-secondary-products
                    v-if="order.orderSecondaryProduct && order.orderSecondaryProduct.length"
                    :secondary-products="order.orderSecondaryProduct"
                />

                <order-impression-kits
                    v-if="order.impressionKit && order.impressionKit.length"
                    :impression-kits="order.impressionKit"
                />

                <order-gift-cards
                    v-if="order.giftCard && order.giftCard.length"
                    :gift-cards="order.giftCard"
                />

                <order-post-purchase-questionnaire-answers
                    v-if="hasPostPurchaseAnyAnswer"
                    :customer="order.customer"
                    :questionnaire="order.postPurchaseQuestionnaire"
                />

                <zendesk-tickets />

                <div v-if="isAnyCustomerImage" class="card">
                    <div class="card-body">
                        <div class="d-flex flex-wrap">
                            <div class="form-group col-12 px-0">
                                <h5 class="text-uppercase bg-light p-2">
                                    Customer Images
                                </h5>
                            </div>
                            <div
                                v-for="(orderProductColor, index) in order.orderProductColor"
                                :key="index"
                                class="d-flex flex-wrap col-12 mb-2"
                            >
                                <label v-if="orderProductColor.images.length" class="font-weight-bold">
                                    {{ `${orderProductColor.productColor.product.name} [${orderProductColor.productColor.name}, size: ${orderProductColor.size}]` }}
                                </label>
                                <div
                                    v-for="(image, imageIndex) in orderProductColor.images"
                                    :key="imageIndex"
                                    class="form-group col-12 row"
                                >
                                    <input
                                        ref="cartLink"
                                        :value="getImageUrl(image)"
                                        type="text"
                                        class="form-control col-8 col-xl-6"
                                        readonly="readonly"
                                    />
                                    <a
                                        class="btn btn-info waves-effect waves-light ml-3"
                                        :href="getImageUrl(image)"
                                        target="_blank"
                                    >
                                        Open
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="order.customer && order.customer.dropbox_sharing_link" class="card">
                    <div class="card-body">
                        <div class="form-group col-12 px-0">
                            <h5 class="text-uppercase bg-light p-2">
                                Dropbox Sharing Link
                            </h5>
                        </div>
                        <div class="d-flex flex-wrap">
                            <div class="form-group col-6">
                                <label>Link:</label>
                                <input
                                    ref="cartLink"
                                    :value="order.customer.dropbox_sharing_link"
                                    type="text"
                                    class="form-control"
                                    readonly="readonly"
                                />
                            </div>
                            <div class="form-group col-xl-2 d-flex align-items-end">
                                <a
                                    class="btn btn-info waves-effect waves-light ml-1"
                                    :href="order.customer.dropbox_sharing_link"
                                    target="_blank"
                                >
                                    Open
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    v-if="order.orderMedia && order.orderMedia.length"
                    class="card"
                >
                    <div class="card-body">
                        <div class="d-flex flex-wrap">
                            <div class="form-group col-12">
                                <label for="note">Customer mobile media</label>
                            </div>
                            <div
                                v-if="orderMediaVideos.length"
                                class="form-group col-12 d-flex flex-wrap"
                            >
                                <h5
                                    class="col-12 d-flex text-uppercase bg-light p-2"
                                >
                                    Videos
                                </h5>
                                <div
                                    v-for="(file, index) in orderMediaVideos"
                                    :key="index"
                                    class="mr-1 mt-2"
                                >
                                    <a
                                        :href="getMediaUrl(file)"
                                        target="_blank"
                                    >
                                        <div
                                            class="btn btn-info waves-effect waves-light mr-1"
                                        >
                                            <span style="display: block">
                                                {{ file.type }}
                                            </span>
                                            <small>
                                                ({{
                                                    file.created_at
                                                        | formatDate
                                                }})
                                            </small>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div
                                v-if="orderMediaImages.length"
                                class="form-group col-12 d-flex flex-wrap"
                            >
                                <h5
                                    class="col-12 d-flex text-uppercase bg-light p-2"
                                >
                                    Images
                                </h5>
                                <div
                                    v-for="(file, index) in orderMediaImages"
                                    :key="index"
                                    class="mr-1 mt-2"
                                >
                                    <a
                                        :href="getMediaUrl(file)"
                                        target="_blank"
                                    >
                                        <img
                                            :src="getMediaUrl(file)"
                                            class="image-mini mr-2"
                                        />
                                    </a>
                                    <div class="text-center mr-1">
                                        <span style="display: block">
                                            {{ file.type }}
                                        </span>
                                        <small>
                                            ({{ file.created_at | formatDate }})
                                        </small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </layout>
</template>

<script>
import config from '@config';
import { mapActions } from 'vuex';
import appConfig from '@src/app.config';
import OrderDiscounts from '@components/orders/details/OrderDiscounts';
import OrderProducts from '@components/orders/details/OrderProducts';
import OrderProductsDetails from '@components/orders/details/OrderProductsDetails';
import OrderSecondaryProducts from '@components/orders/details/OrderSecondaryProducts';
import OrderImpressionKits from '@components/orders/details/OrderImpressionKits';
import OrderGiftCards from '@components/orders/details/OrderGiftCards';
import OrderPostPurchaseQuestionnaireAnswers from '@components/orders/details/OrderPostPurchaseQuestionnaireAnswers';
import OrderDetailsInfoTable from '@components/orders/details/OrderDetailsInfoTable';
import OrderShippingAddress from '@components/orders/details/OrderShippingAddress';
import OrderStatus from '@components/orders/details/OrderStatus';
import OrderLogisticsStatus from '@components/orders/details/OrderLogisticsStatus';
import ZendeskTickets from '@components/factory-data/ZendeskTickets';

export default {
    page: {
        title: 'Order Details',
        meta: [{ name: 'description', content: appConfig.description }]
    },

    components: {
        OrderDiscounts,
        OrderProducts,
        OrderProductsDetails,
        OrderSecondaryProducts,
        OrderImpressionKits,
        OrderGiftCards,
        OrderPostPurchaseQuestionnaireAnswers,
        OrderDetailsInfoTable,
        OrderShippingAddress,
        OrderStatus,
        OrderLogisticsStatus,
        ZendeskTickets,
    },

    data() {
        return {
            title: 'Order Details',
            items: [
                {
                    text: 'Upstep',
                    to: '/'
                },
                {
                    text: 'Orders',
                    to: '/orders'
                },
                {
                    text: 'Order Details',
                    active: true
                }
            ],
            orderId: {},
            order: {}
        };
    },

    computed: {
        products() {
            if (!this.order.id) {
                return [];
            }

            return this.order.orderProductColor.map(item => ({
                ...item,
                product: item.productColor.product
            }));
        },

        orderMediaVideos() {
            if (
                this.order &&
                this.order.orderMedia &&
                this.order.orderMedia.length
            ) {
                return this.order.orderMedia
                    .filter(file => file.media.type === 'video')
                    .sort((a, b) => (a.created_at < b.created_at ? -1 : 1));
            }

            return [];
        },

        orderMediaImages() {
            if (
                this.order &&
                this.order.orderMedia &&
                this.order.orderMedia.length
            ) {
                return this.order.orderMedia
                    .filter(file => file.media.type === 'image')
                    .sort((a, b) => (a.created_at < b.created_at ? -1 : 1));
            }

            return [];
        },

        hasPostPurchaseAnyAnswer() {
            if (!this.order.postPurchaseQuestionnaire) {
                return false;
            }

            const nonDefaultAnswers = this.order.postPurchaseQuestionnaire.answers.flatMap(
                answersAccount =>
                    answersAccount.answers.filter(answer => !answer.isDefault)
            );

            return !!nonDefaultAnswers.length;
        },

        isAnyCustomerImage() {
            return (
                this.order.orderProductColor &&
                this.order.orderProductColor.some(
                    orderProductColor => orderProductColor.images.length
                )
            );
        }
    },

    mounted() {
        this.orderId = this.$route.params.id;
        this.fetchOrder();
    },

    methods: {
        ...mapActions({
            getOrder: 'orders/show'
        }),

        async fetchOrder() {
            try {
                this.order = await this.getOrder(this.orderId);
            } catch (err) {
                console.error(err);

                this.$toasterError();
            }
        },

        getImageUrl(image) {
            return `${config.mediaUrl}/images/${image.date}/${image.filename ||
                image.id}.${image.extension}`;
        },

        getMediaUrl(file) {
            const { media } = file;

            const baseUrl = file.on_digital_ocean
                ? `${config.digitalOceanUrl}/media`
                : config.mediaUrl;

            return `${baseUrl}/${media.type}s/${media.date}/${media.filename ||
                media.id}.${media.extension}`;
        },

        onUpdate(data) {
            this.order = {...this.order, ...data};
        }
    }
};
</script>

<style lang="scss" scoped>
.image-mini {
    height: 300px;
    width: auto;
    max-width: 500px;
}
</style>
