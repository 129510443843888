<template>
    <div class="card-box">
        <h5 class="p-2 m-0 mb-2 text-uppercase">
            Logistics Status
        </h5>
        <div class="d-flex flex-wrap">
            <div class="form-group col-xl-6">
                <b-form-select
                    v-model="logisticsStatus"
                    :options="logisticsStatuses"
                />
            </div>
            <div class="form-group col-xl-2 d-flex align-items-end">
                <button
                    type="button"
                    class="btn btn-danger waves-effect waves-light ml-1"
                    @click="changeLogisticsStatus"
                >
                    Change
                </button>
            </div>
        </div>
        <order-history-table :histories="logisticsHistory" />
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import OrderHistoryTable from '@components/orders/OrderHistoryTable';

export default {
    components: {
        OrderHistoryTable
    },

    props: {
        order: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            logisticsStatus: null,
            logisticsHistory: [],
            logisticsStatuses: [
                { text: 'ORDER_RECEIVED', value: 'ORDER_RECEIVED', id: 0 },
                {
                    text: 'APP_ORDER_RECEIVED',
                    value: 'APP_ORDER_RECEIVED',
                    id: 1
                },
                {
                    text: 'APP_STL_UPLOADED',
                    value: 'APP_STL_UPLOADED',
                    id: 2
                },
                {
                    text: 'APP_RETAKE',
                    value: 'APP_RETAKE',
                    id: 3
                },
                {
                    text: 'APP_RETAKE_UPLOADED',
                    value: 'APP_RETAKE_UPLOADED',
                    id: 4
                },
                {
                    text: 'APP_PENDING_VIDEOS',
                    value: 'APP_PENDING_VIDEOS',
                    id: 5
                },
                {
                    text: 'APP_VIDEOS_UPLOADED',
                    value: 'APP_VIDEOS_UPLOADED',
                    id: 6
                },
                { text: 'APP_RETAKE_VIDEO', value: 'APP_RETAKE_VIDEO', id: 7 },
                {
                    text: 'APP_CREATE_3D_ON_AGISOFT',
                    value: 'APP_CREATE_3D_ON_AGISOFT',
                    id: 8
                },
                {
                    text: 'APP_READY_FOR_DESIGN',
                    value: 'APP_READY_FOR_DESIGN',
                    id: 9
                }
            ]
        };
    },

    mounted() {
        this.logisticsStatus = this.order.logistics_status;
        this.logisticsHistory = [...this.order.logisticsHistory];
    },

    methods: {
        ...mapActions({
            changeOrderLogisticsStatus: 'orders/changeLogisticsStatus'
        }),

        async changeLogisticsStatus() {
            try {
                const newStatus = await this.changeOrderLogisticsStatus({
                    id: this.order.id,
                    logisticsStatus: this.logisticsStatus
                });

                this.$toaster('Status has been updated!');

                this.logisticsHistory = [newStatus, ...this.logisticsHistory];
            } catch (err) {
                console.error(err);

                this.$toasterError();
            }
        }
    }
};
</script>
